import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import cuid from 'cuid';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledProductDetailAttachmentButton, StyledProductDetailText } from './ProductDetail.styled';

const ProductDetailAccordionSummary = (props) => <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />;

const ProductDetailULContentRenderer = ({ content = [] }) => {
  return (
    <ul>
      {content.map((item) => (
        <li key={cuid()} style={{ listStyle: 'none' }}>
          <Box fontSize="16px" lineHeight="24px" color="text.secondary" mb={2}>
            &#8226; {item}
          </Box>
        </li>
      ))}
    </ul>
  );
};

ProductDetailULContentRenderer.propTypes = {
  content: PropTypes.array,
};

const ProductDetailDocumentContentRenderer = ({ content = [] }) => {
  return content.map((item) => (
    <StyledProductDetailAttachmentButton variant="text" key={item.link} href={item.link} target="_blank" rel="noreferrer">
      <PictureAsPdfIcon fontSize="large" />
      {item.description}
    </StyledProductDetailAttachmentButton>
  ));
};

ProductDetailDocumentContentRenderer.propTypes = {
  content: PropTypes.array,
};

const ProductDetailText = ({ text = '' }) => <StyledProductDetailText>{text}</StyledProductDetailText>;

ProductDetailText.propTypes = {
  text: PropTypes.string,
};

const ProductDetailCoverageTypeRenderer = ({ content = [] }) => {
  return (
    <Grid container spacing={1}>
      {content.map((item) => (
        <Grid key={cuid()} item>
          <Box p="18px 16px" bgcolor="grey.100" width={260} height={56} boxSizing="border-box" fontSize="14px" lineHeight="20px" borderRadius="4px">
            {item.description}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

ProductDetailCoverageTypeRenderer.propTypes = {
  content: PropTypes.array,
};

export {
  ProductDetailAccordionSummary,
  ProductDetailCoverageTypeRenderer,
  ProductDetailDocumentContentRenderer,
  ProductDetailText,
  ProductDetailULContentRenderer,
};
