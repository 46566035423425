import { Box, Grid } from '@material-ui/core';
import { Alert, Card, Link, Typography } from 'components/AORedesign';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { Feature } from 'components/Feature';
import { Col } from 'components/ScreenSmith';
import { MAILING_AND_CONTACT_INFORMATION_LINK } from 'constants/static';
import React, { useState } from 'react';

const GuidesAndContacts = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadPdf = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');

    handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  return (
    <Col xs={6}>
      <Card style={{ height: '100%' }}>
        <Box mb={2}>
          <Grid>
            <Box mt={1} mb={4}>
              <Typography variant="h6">Guides & Contacts</Typography>
            </Box>
            {isDownloadingPdfErrorVisible && <Alert color="error" alertTitleMessage={downloadingPdfError} />}
            <Box mt={2} mb={4}>
              <Box>
                <Typography variant="button" display="block" gutterBottom>
                  GUIDE
                </Typography>
              </Box>
              {/* Hiding links under https://amfament.atlassian.net/browse/E1PMAO-13450 while training team works on getting new links. No timeframe */}
              <Feature allowed={[]}>
                <Box mt={1}>
                  <Link onClick={resetErrorVisibility} href="" endIcon="pdf">
                    Billing Guide
                  </Link>
                </Box>
              </Feature>
              <Box mt={1}>
                <Typography>
                  <Link data-pdf="/resources/12-Pay_FAQs.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                    Commercial Lines 12-Pay EFT FAQs
                  </Link>
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography>
                  <Link data-pdf="/resources/EFT_FAQs.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                    EFT Frequently Asked Questions
                  </Link>
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography>
                  <Link data-pdf="/resources/OnlineBill_FAQs.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                    Payment Frequently Asked Questions
                  </Link>
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="button" display="block" gutterBottom>
                CONTACTS
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                <Link data-pdf="/resources/flier_MSACS.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                  Customer Services Overview
                </Link>
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                <Link data-pdf="/resources/CustServQuickRef%20C.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                  Customer Services Phone Tree Quick Reference
                </Link>
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                <Link href={MAILING_AND_CONTACT_INFORMATION_LINK} endIcon="openInNew">
                  Mailing & Contact Information
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Card>
    </Col>
  );
};

export default GuidesAndContacts;
