import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Accordion, AccordionDetails, Box, Col, Divider, Paper, Row, Skeleton, Typography } from 'components/AORedesign';
import { GET_APPETITE } from '../../../constants/api';
import { AppetiteServiceUnavailable } from '../../AppetiteServiceUnavailable';
import {
  ProductDetailAccordionSummary,
  ProductDetailCoverageTypeRenderer,
  ProductDetailDocumentContentRenderer,
  ProductDetailText,
  ProductDetailULContentRenderer,
} from './ProductDetail.components';
import {
  ProductDetailMapLegend,
  StyledProductDetail,
  StyledProductDetailAccordionHeader,
  StyledProductDetailAccordionTypography,
  StyledProductDetailOverview,
} from './ProductDetail.styled';
import ProductDetailMap from './ProductDetailMap';

const ProductDetail = () => {
  const [loading, setLoading] = useState(true);
  const [productDetail, setProductDetail] = useState();
  const [serviceUnavailable, setServiceUnavailable] = useState(false);

  const { productId } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const getAppetiteProductUrl = `${GET_APPETITE}/product/${productId}`;
        const response = await axios.get(getAppetiteProductUrl);
        setProductDetail(response.data.ProductsDetails);
      } catch {
        setServiceUnavailable(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [productId]);

  const renderSection = (title, content, RendererComponent) =>
    content?.length > 0 && (
      <Accordion>
        <ProductDetailAccordionSummary id={`${title}-header`} aria-controls={`${title}-content`}>
          <StyledProductDetailAccordionHeader>{title}</StyledProductDetailAccordionHeader>
        </ProductDetailAccordionSummary>
        <AccordionDetails style={{ maxWidth: '60%' }}>
          <StyledProductDetailAccordionTypography component="div">
            <RendererComponent content={content}></RendererComponent>
          </StyledProductDetailAccordionTypography>
        </AccordionDetails>
      </Accordion>
    );

  if (loading) {
    return (
      <Box m={1}>
        <Skeleton height={200} />
      </Box>
    );
  }

  return (
    <StyledProductDetail id={'ProductDetail'}>
      {serviceUnavailable && <AppetiteServiceUnavailable />}
      {!serviceUnavailable && (
        <>
          <StyledProductDetailOverview>
            <Box mb={2} fontFamily={'Montserrat'} fontWeight={500} fontSize={28}>
              {productDetail?.name}
            </Box>
            <Divider />
            <Row spacing={2}>
              <Col xs>
                <Box mb={3}>
                  <ProductDetailText text={productDetail?.description}></ProductDetailText>
                </Box>
                {productDetail?.coverageType?.length > 0 && (
                  <Typography variant={'h6'} component="div">
                    <Box fontWeight={500} fontSize={'18px'} lineHeight={'32px'} mb={2}>
                      Coverages Provided
                    </Box>
                  </Typography>
                )}
                <ProductDetailCoverageTypeRenderer content={productDetail?.coverageType}></ProductDetailCoverageTypeRenderer>
              </Col>
              <Col xs>
                <Box p={1}>
                  <Paper variant={'outlined'}>
                    <Typography component="div">
                      <Box fontSize={'16px'} lineHeight={'24px'} letterSpacing={'0.15px'} fontWeight={500} mb={1.25}>
                        Eligible States
                      </Box>
                    </Typography>
                    <Box mb={2}>
                      <ProductDetailMap height={313} width={493} states={productDetail?.States} />
                    </Box>
                    <StyledProductDetailAccordionTypography component="div">
                      <Box display="flex" alignItems={'center'}>
                        <ProductDetailMapLegend />
                        <Typography component="div">
                          <Box color={'text.secondary'}>This product is written in these states</Box>
                        </Typography>
                      </Box>
                    </StyledProductDetailAccordionTypography>
                  </Paper>
                </Box>
              </Col>
            </Row>
          </StyledProductDetailOverview>

          {renderSection('Eligibility', productDetail?.highlights, ProductDetailULContentRenderer)}
          {renderSection('Underwriting Guidelines', productDetail?.guidelines, ProductDetailULContentRenderer)}
          {renderSection('Documents', productDetail?.attachments, ProductDetailDocumentContentRenderer)}
        </>
      )}
    </StyledProductDetail>
  );
};

export default ProductDetail;
