const ROUTE_ADMINISTRATION = '/administration';

const ROUTE_AGENCY_TOOLKIT = '/agency-toolkit';
const ROUTE_AGENCY_TOOLKIT_ABSREPORTS = '/absreports';
const ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT = '/annual-mileage-validation-report';
const ROUTE_AGENCY_TOOLKIT_APNEREPORT = '/apnereport';
const ROUTE_AGENCY_TOOLKIT_BMREPORTS = '/bmreports';
const ROUTE_AGENCY_TOOLKIT_CCREPORTS = '/ccreports';
const ROUTE_AGENCY_TOOLKIT_DBCREPORT = '/dbcreport';
const ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS = '/lbareports';
const ROUTE_AGENCY_TOOLKIT_PLRREPORTS = '/plrreports';
const ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS = '/tareports';

const ROUTE_AGENT_MANAGEMENT = '/agent-management';
const ROUTE_AGENT_MANAGEMENT_ADD_AGENT = '/add-agent';
const ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT = '/search-agent';

const ROUTE_ANNOUNCEMENTS = '/announcements';

const ROUTE_APPLICATION_ADMIN = '/application-admin';
const ROUTE_REDESIGN_APP_ADMIN = '/app-admin';
const ROUTE_REDESIGN_USER_ADMIN = '/user-admin-redesign';

const ROUTE_REDESIGN_HEALTH_CHECKS = '/health_checks';

const ROUTE_ACCOUNT_ADMIN = `/account-admin`;
const ROUTE_ACCOUNT_ADMIN_REGISTRATION_MATERIALS = `${ROUTE_ACCOUNT_ADMIN}/registration-materials`;
const ROUTE_BILLING = '/billing';
const ROUTE_BILLING_DETAILS = `${ROUTE_BILLING}/details`;
const ROUTE_BILLING_SERVICES = '/billing-services';
const ROUTE_BILLING_SERVICES_REPORTS = `${ROUTE_BILLING_SERVICES}/reports`;
const ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE = `${ROUTE_BILLING_SERVICES_REPORTS}/payments-made`;
const ROUTE_COMMERCIAL_LINES_AUDIT_FORMS = `/commercial-lines-audit-forms`;
const ROUTE_COMMERCIAL_LINES_QUOTING = '/commercial-lines-quoting';
const ROUTE_ACCOUNT_DETAIL = `/account-detail`;
const ROUTE_CL_PRODUCT_DETAIL = `${ROUTE_COMMERCIAL_LINES_QUOTING}/product-detail`;
const ROUTE_CLAIMS = '/claims';
const ROUTE_CLAIMS_INQ = '/claims-inq';
const ROUTE_CLIENTS = '/clients';
const ROUTE_DASH = '/dash';
const ROUTE_DASHBOARD = '/dashboard';
const ROUTE_EXTERNAL = '/external';
const ROUTE_FARMRANCH = '/farmranch';
const ROUTE_FLOOD = `/flood`;
const ROUTE_HOME = '/';
const ROUTE_EMPLOYEE_HOME = '/home';
const ROUTE_NEWS_ARCHIVED = `/news-articles/home-page-archived`;
const ROUTE_NEWS_ARTICLE_ADMIN = '/news-admin';
const ROUTE_NEWS_ADD = '/news-admin/add-news';
const ROUTE_NEWS_EDIT = '/news-admin/edit-news';
const ROUTE_NEWS_ARTICLE_ADMIN_SEARCH = '/news-admin/news-articles';

const ROUTE_QUOTING_ONE_POC = '/quoting-one-poc';
const ROUTE_QUOTES = '/quotes';
const ROUTE_R_ANNOUNCEMENTS = `${ROUTE_DASH}${ROUTE_ANNOUNCEMENTS}`;
const ROUTE_STORM_REFERENCE = '/storm-reference';
const ROUTE_UNDERWRITING = '/underwriting';
const ROUTE_USER_ADMIN = '/user-admin';
const ROUTE_USER_ADMIN_ADD_GROUP = '/add-group';
const ROUTE_USER_ADMIN_ADD_USER = '/add-user';
const ROUTE_USER_ADMIN_EDIT_USER = '/edit-user';
const ROUTE_USER_ADMIN_GROUP_INFORMATION = '/group-information';
const ROUTE_USER_ADMIN_SEARCH_USER = '/search-user';
const ROUTE_USER_ADMIN_VIEW_AGENCY_INFO = '/view-agency-info';
const ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY = '/view-change-history';
const ROUTE_CLIX = '/clix';
const ROUTE_WILDCARD = '/*';

const ROUTE_REDESIGN_HOME_PAYMENT_BALANCE = `${ROUTE_EMPLOYEE_HOME}/payment-balance`;
const ROUTE_REDESIGN_HOME_PAYMENT_HISTORY = `${ROUTE_EMPLOYEE_HOME}/payment-history`;
const ROUTE_REDESIGN_HOME_MANAGE_CURRENT_PAYMENT = `${ROUTE_EMPLOYEE_HOME}/manage-payment`;
const ROUTE_REDESIGN_REPORTS = '/reports';
const ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT = `${ROUTE_REDESIGN_REPORTS}/book-management`;
const ROUTE_REDESIGN_REPORTS_AGENT_ABSREPORTS = `${ROUTE_REDESIGN_REPORTS}/absreports`;
const ROUTE_REDESIGN_REPORTS_AGENT_APNEREPORT = `${ROUTE_REDESIGN_REPORTS}/apnereport`;
const ROUTE_REDESIGN_REPORTS_AGENT_LOSSESBYAGENTREPORTS = `${ROUTE_REDESIGN_REPORTS}/lbareports`;
const ROUTE_REDESIGN_REPORTS_AGENT_PLRREPORTS = `${ROUTE_REDESIGN_REPORTS}/plrreports`;
const ROUTE_REDESIGN_REPORTS_AGENT_ANNUALMILEAGEVALIDATIONREPORT = `${ROUTE_REDESIGN_REPORTS}/amvreport`;
const ROUTE_REDESIGN_REPORTS_AGENT_CCREPORTS = `${ROUTE_REDESIGN_REPORTS}/ccreports`;
const ROUTE_REDESIGN_REPORTS_BILLING_TAR = `${ROUTE_REDESIGN_REPORTS}/tareports`;
const ROUTE_REDESIGN_REPORTS_BILLING_PAYMENT_BALANCE_REPORT = `${ROUTE_REDESIGN_REPORTS}/pbreports`;
const ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_MADE_REPORT = `${ROUTE_REDESIGN_REPORTS}/pmreports`;
const ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_DUE = `${ROUTE_REDESIGN_REPORTS}/payment-due`;
const ROUTE_REDESIGN_REPORTS_CL_RENEWALS = `${ROUTE_REDESIGN_REPORTS}/cl-renewals`;
const ROUTE_REDESIGN_REPORTS_DIRECT_BILL_COMISSION = `${ROUTE_REDESIGN_REPORTS}/dbcreports`;
const ROUTE_REDESIGN_RESOURCES = '/resources';
const ROUTE_REDESIGN_RESOURCES_MARKETING = `${ROUTE_REDESIGN_RESOURCES}/marketing`;
const ROUTE_REDESIGN_RESOURCES_TRAINING = `${ROUTE_REDESIGN_RESOURCES}/training`;
const ROUTE_REDESIGN_RESOURCES_UNDERWRITING = `${ROUTE_REDESIGN_RESOURCES}/underwriting`;
const ROUTE_REDESIGN_RESOURCES_STORM = `${ROUTE_REDESIGN_RESOURCES}/storm`;

export {
  ROUTE_ACCOUNT_ADMIN,
  ROUTE_ACCOUNT_ADMIN_REGISTRATION_MATERIALS,
  ROUTE_ACCOUNT_DETAIL,
  ROUTE_ADMINISTRATION,
  ROUTE_AGENCY_TOOLKIT,
  ROUTE_AGENCY_TOOLKIT_ABSREPORTS,
  ROUTE_AGENCY_TOOLKIT_ANNUALMILEAGEVALIDATIONREPORT,
  ROUTE_AGENCY_TOOLKIT_APNEREPORT,
  ROUTE_AGENCY_TOOLKIT_BMREPORTS,
  ROUTE_AGENCY_TOOLKIT_CCREPORTS,
  ROUTE_AGENCY_TOOLKIT_DBCREPORT,
  ROUTE_AGENCY_TOOLKIT_LOSSESBYAGENTREPORTS,
  ROUTE_AGENCY_TOOLKIT_PLRREPORTS,
  ROUTE_AGENCY_TOOLKIT_TRANSACTIONACTIVITYREPORTS,
  ROUTE_AGENT_MANAGEMENT,
  ROUTE_AGENT_MANAGEMENT_ADD_AGENT,
  ROUTE_AGENT_MANAGEMENT_SEARCH_AGENT,
  ROUTE_ANNOUNCEMENTS,
  ROUTE_APPLICATION_ADMIN,
  ROUTE_BILLING,
  ROUTE_BILLING_DETAILS,
  ROUTE_BILLING_SERVICES,
  ROUTE_BILLING_SERVICES_REPORTS,
  ROUTE_BILLING_SERVICES_REPORTS_PAYMENTS_MADE,
  ROUTE_CL_PRODUCT_DETAIL,
  ROUTE_CLAIMS,
  ROUTE_CLAIMS_INQ,
  ROUTE_CLIENTS,
  ROUTE_CLIX,
  ROUTE_COMMERCIAL_LINES_AUDIT_FORMS,
  ROUTE_DASH,
  ROUTE_DASHBOARD,
  ROUTE_EMPLOYEE_HOME,
  ROUTE_EXTERNAL,
  ROUTE_FARMRANCH,
  ROUTE_FLOOD,
  ROUTE_HOME,
  ROUTE_NEWS_ADD,
  ROUTE_NEWS_ARCHIVED,
  ROUTE_NEWS_ARTICLE_ADMIN,
  ROUTE_NEWS_ARTICLE_ADMIN_SEARCH,
  ROUTE_NEWS_EDIT,
  ROUTE_QUOTES,
  ROUTE_QUOTING_ONE_POC,
  ROUTE_R_ANNOUNCEMENTS,
  ROUTE_REDESIGN_APP_ADMIN,
  ROUTE_REDESIGN_HEALTH_CHECKS,
  ROUTE_REDESIGN_HOME_MANAGE_CURRENT_PAYMENT,
  ROUTE_REDESIGN_HOME_PAYMENT_BALANCE,
  ROUTE_REDESIGN_HOME_PAYMENT_HISTORY,
  ROUTE_REDESIGN_REPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_ABSREPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_ANNUALMILEAGEVALIDATIONREPORT,
  ROUTE_REDESIGN_REPORTS_AGENT_APNEREPORT,
  ROUTE_REDESIGN_REPORTS_AGENT_CCREPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_LOSSESBYAGENTREPORTS,
  ROUTE_REDESIGN_REPORTS_AGENT_PLRREPORTS,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENT_BALANCE_REPORT,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_DUE,
  ROUTE_REDESIGN_REPORTS_BILLING_PAYMENTS_MADE_REPORT,
  ROUTE_REDESIGN_REPORTS_BILLING_TAR,
  ROUTE_REDESIGN_REPORTS_BOOK_MANAGEMENT,
  ROUTE_REDESIGN_REPORTS_CL_RENEWALS,
  ROUTE_REDESIGN_REPORTS_DIRECT_BILL_COMISSION,
  ROUTE_REDESIGN_RESOURCES,
  ROUTE_REDESIGN_RESOURCES_MARKETING,
  ROUTE_REDESIGN_RESOURCES_STORM,
  ROUTE_REDESIGN_RESOURCES_TRAINING,
  ROUTE_REDESIGN_RESOURCES_UNDERWRITING,
  ROUTE_REDESIGN_USER_ADMIN,
  ROUTE_STORM_REFERENCE,
  ROUTE_UNDERWRITING,
  ROUTE_USER_ADMIN,
  ROUTE_USER_ADMIN_ADD_GROUP,
  ROUTE_USER_ADMIN_ADD_USER,
  ROUTE_USER_ADMIN_EDIT_USER,
  ROUTE_USER_ADMIN_GROUP_INFORMATION,
  ROUTE_USER_ADMIN_SEARCH_USER,
  ROUTE_USER_ADMIN_VIEW_AGENCY_INFO,
  ROUTE_USER_ADMIN_VIEW_CHANGE_HISTORY,
  ROUTE_WILDCARD,
};
