import { useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import USAMap from '../../AORedesign/USAMap';

const ProductDetailMap = ({ states = {}, ...props }) => {
  const theme = useTheme();

  const statesCustomConfig = () => {
    return Object.keys(states).reduce((config, state) => {
      const stateName = state.split('-').pop();
      config[stateName] = { fill: theme.palette.primary.main };
      return config;
    }, {});
  };

  if (!Object.keys(states).length) return null;

  return <USAMap customize={statesCustomConfig()} {...props} />;
};

ProductDetailMap.propTypes = {
  states: PropTypes.object,
};

export default ProductDetailMap;
