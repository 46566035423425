import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Card, Typography } from 'components/AORedesign';
import { ROUTE_DASH } from 'constants/routes';

const NotFoundPage = () => {
  return (
    <Box p={3}>
      <Card>
        <Typography variant="h1" gutterBottom>
          404 - Not Found!
        </Typography>
        <Link to={ROUTE_DASH}>
          <Button variant="outlined">Go Home</Button>
        </Link>
      </Card>
    </Box>
  );
};

NotFoundPage.propTypes = {
  children: PropTypes.node,
};

export default NotFoundPage;
