import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FormControl, FormHelperText } from '@material-ui/core/';
import { Box, Button, Dialog, Paper, Row, Select, Typography } from '../../AORedesign';

const FindProductToQuoteNAICSCodeDialog = ({
  selectedProduct,
  codeOptions,
  naicsCodeText,
  handleCancelOnClick,
  handleMapCodeOnClick,
  selectedClassificationCode,
  setSelectedClassificationCode,
  businessLocationType,
  businessTypeKeywordsNaicCode,
}) => {
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (!codeOptions.length) {
      setSelectedClassificationCode('');
    }
  }, [codeOptions]);

  const renderedCodeOptions = () => {
    let results = codeOptions;

    if (codeOptions.length > 0 && selectedProduct) {
      const validOptionsArray = businessLocationType.split(' ');
      const validOption = validOptionsArray[validOptionsArray.length - 1];
      const selectedProductObject = JSON.parse(selectedProduct);
      const { StatusTags } = selectedProductObject;
      const isLROOptionEligible = businessTypeKeywordsNaicCode?.startsWith('5311');
      // Filter out options based on selected LRO if it is MSE, BOP, an LRO eligible (naic starts with 5311)
      if (isLROOptionEligible && StatusTags['LOB-BOP'] && StatusTags['PAS-MSE']) {
        results = codeOptions.filter((option) => option.title.startsWith(`${validOption}-`));
      }
    }

    return results.map((option) => {
      return { ...option, value: option.id, label: option.title };
    });
  };

  const handleClassificationOnChange = (e) => {
    setSelectedClassificationCode(e.target.value);
    if (!selectedClassificationCode) {
      setDisplayError(false);
    }
  };

  const handleProceed = () => {
    if (!selectedClassificationCode) {
      setDisplayError(true);
    } else {
      handleMapCodeOnClick();
      handleCancelOnClick();
    }
  };

  return (
    <Dialog
      open={codeOptions?.length > 0}
      onClose={() => handleCancelOnClick()}
      aria-labelledby="find-product-to-quote-naics-code-dialog-title"
      aria-describedby="find-product-to-quote-naics-code-dialog-description"
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper>
        <Box px={1}>
          <Typography component="div">
            <Box fontWeight={500} fontSize={20} mb={2}>
              Multiple Classifications Found
            </Box>
          </Typography>

          <Typography component="div">
            <Box color="text.secondary" mb={3}>
              {`The business type ${naicsCodeText} qualifies for multiple classifications. Please select the most appropriate class
                from the list to proceed.`}
            </Box>
          </Typography>

          <Box mb={8}>
            <FormControl component="fieldset">
              <Select
                label="Classification Options"
                id="find-product-to-quote-ncais-code-dialog-select"
                value={selectedClassificationCode}
                menuItems={renderedCodeOptions() ?? []}
                onChange={handleClassificationOnChange}
              />
              {displayError && <FormHelperText error={true}>Select classification option</FormHelperText>}
            </FormControl>
          </Box>

          <Row justifyContent="flex-end" spacing={1}>
            <Button variant="text" color="secondary" onClick={handleCancelOnClick}>
              Cancel
            </Button>
            <Button variant="text" color="secondary" onClick={handleProceed}>
              Proceed
            </Button>
          </Row>
        </Box>
      </Paper>
    </Dialog>
  );
};

FindProductToQuoteNAICSCodeDialog.propTypes = {
  selectedProduct: PropTypes.string,
  codeOptions: PropTypes.array,
  handleCancelOnClick: PropTypes.func,
  handleMapCodeOnClick: PropTypes.func,
  naicsCodeText: PropTypes.string,
  selectedClassificationCode: PropTypes.string,
  setSelectedClassificationCode: PropTypes.func,
  businessLocationType: PropTypes.string,
  businessTypeKeywordsNaicCode: PropTypes.string,
};

export default FindProductToQuoteNAICSCodeDialog;
