import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import React, { useState } from 'react';
import { Alert, Box, Card, Col, Link, Typography } from '../../AORedesign';

const FormsAndRequests = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadPdf = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');

    handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  return (
    <Col xs={6}>
      <Card style={{ height: '100%' }}>
        <Box mb={2}>
          <Box mt={1} mb={4}>
            <Typography variant="h6">Forms & Requests</Typography>
          </Box>
          {isDownloadingPdfErrorVisible && <Alert color="error" alertTitleMessage={downloadingPdfError} />}
          <Box mt={2} mb={4}>
            <Box>
              <Typography variant="button" display="block" gutterBottom>
                FORMS
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                <Link data-pdf="/resources/Policyholder EFT Authorization Form.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                  Customer EFT Enrollment
                </Link>
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                <Link data-pdf="/resources/Statement_Of_No_Loss.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                  Statement of No Loss
                </Link>
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography>
                <Link data-pdf="/resources/Agency_EFT_Commission_Deposit_Form.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                  Electronic Commission & Agency Sweep Authorization
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography variant="button" display="block" gutterBottom>
              REQUESTS
            </Typography>{' '}
          </Box>
          <Box mt={1}>
            <Typography>
              <Link href={'https://brandfolder.com/s/38xk5873nvp8ntcwhw256hxv'} endIcon="openInNew">
                Change of Agency Information Request
              </Link>
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography>
              <Link
                onClick={resetErrorVisibility}
                href="mailto:ITSupportServices@msagroup.com?subject=Download/Retransmit&body=Please provide the required information below to request a download or retransmission of a policy.%0A%0ANamed Insured:%0APolicy number:%0AAgency code:%0ATransaction Type:%0AEffective Date of Download:%0AReason for Download Retransmit:%0A%0ABe advised that items transmitted after Jan. 8, 2018 can be obtained via self-service at your agency's IVANS Exchange.%0A%0APlease note that only the last transaction processed on a policy can be retransmitted.%0A%0ADid you know the IVANS Exchange website offers agents the ability to log-in and self-serve all of your download needs? IVANS can assist you with logging into IVANS Exchange and provide instructions on how to retrieve Main Street America downloads since our conversion on Jan. 8, 2018.  The IVANS Exchange Help Desk can be reached at support@ivansinsurance.com"
                endIcon="mail">
                Download/Retransmit
              </Link>
            </Typography>
          </Box>
        </Box>
      </Card>
    </Col>
  );
};

export default FormsAndRequests;
