import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import {
  AOProductCardRecommendedMarker,
  StyledAOProductCard,
  StyledAOProductCardButton,
  StyledAOProductCardContainer,
  StyledAOProductCardDescription,
  StyledAOProductCardGrid,
  StyledAOProductCardIcon,
  StyledAOProductCardIconContainer,
  StyledAOProductCardNoIcon,
  StyledAOProductCardRadio,
  StyledAOProductCardTitle,
} from './AOProductCard.styled';

const AOProductCard = ({
  products,
  idKey,
  recommendedKey,
  nameKey,
  logoUrlKey,
  descriptionKey,
  onChangeCallback,
  error,
  onLearnMoreClick,
  selectedProduct,
}) => {
  const alphabeticalComparisonFunction = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const recommendedProducts = products
    ?.filter((product) => product.StatusTags[recommendedKey])
    ?.sort(alphabeticalComparisonFunction);

  const otherProducts = products
    ?.filter((product) => !product.StatusTags[recommendedKey])
    ?.sort(alphabeticalComparisonFunction);

  const handleOnChange = (e) => {
    onChangeCallback(e.target.value);
  };

  return !products?.length ? null : (
    <StyledAOProductCardGrid>
      {/* Move recommended products to the front and keep alphabetical order for both sets*/}
      {[...recommendedProducts, ...otherProducts].map((product) => (
        <div key={`${product[idKey]}`}>
          <Card>
            <StyledAOProductCard selected={selectedProduct === JSON.stringify(product)}>
              {product.StatusTags[recommendedKey] && (
                <AOProductCardRecommendedMarker>RECOMMENDED</AOProductCardRecommendedMarker>
              )}
              <StyledAOProductCardRadio
                checked={selectedProduct === JSON.stringify(product)}
                onChange={handleOnChange}
                value={JSON.stringify(product)}
              />
              <StyledAOProductCardContainer>
                {product[logoUrlKey]?.length ? (
                  <StyledAOProductCardIconContainer>
                    <StyledAOProductCardIcon src={product[logoUrlKey]} />
                  </StyledAOProductCardIconContainer>
                ) : (
                  <StyledAOProductCardNoIcon />
                )}
                {product[nameKey] && (
                  <StyledAOProductCardTitle
                    variant="h6"
                    id={`${product[nameKey].toLowerCase().replace(':', '').split(' ').join('-')}`}>
                    {product[nameKey]}
                  </StyledAOProductCardTitle>
                )}
                {product[descriptionKey] && (
                  <StyledAOProductCardDescription variant="body1">
                    {product[descriptionKey]}
                  </StyledAOProductCardDescription>
                )}
                {product[idKey] && (
                  <StyledAOProductCardButton
                    color="secondary"
                    onClick={(e) => onLearnMoreClick(e, JSON.stringify(product))}>
                    Learn More
                  </StyledAOProductCardButton>
                )}
              </StyledAOProductCardContainer>
            </StyledAOProductCard>
          </Card>
          {error ? (
            <Typography component={'div'}>
              <Box fontSize={12} color="error.main" mt={0.5}>
                Select a Product.
              </Box>
            </Typography>
          ) : null}
        </div>
      ))}
    </StyledAOProductCardGrid>
  );
};

AOProductCard.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  idKey: PropTypes.string,
  recommendedKey: PropTypes.string,
  nameKey: PropTypes.string,
  descriptionKey: PropTypes.string,
  logoUrlKey: PropTypes.string,
  onChangeCallback: PropTypes.func,
  error: PropTypes.bool,
  onLearnMoreClick: PropTypes.func,
  selectedProduct: PropTypes.string,
};

export default AOProductCard;
