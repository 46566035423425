import MuiFormControl from '@material-ui/core/FormControl';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiListSubheader from '@material-ui/core/ListSubheader';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';

import { componentIdStringGenerator } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: `${rem(280)}`,

    [theme.breakpoints.down('sm')]: {
      minWidth: `${rem(250)}`,
    },
  },
}));

const Select = ({ error, helperText, menuItems, menuItemGroups, ...props }) => {
  const classes = useStyles();

  const labelId = componentIdStringGenerator('Select-Label', props.id ? props.id : props.label ?? '');

  const renderMenuItems = (menuItemsArg) =>
    menuItemsArg?.map((menuItem) => (
      <MuiMenuItem
        key={`${menuItem.value}-${menuItem.label}`}
        id={componentIdStringGenerator('Select-Item', menuItem.value)}
        disabled={menuItem.disabled}
        value={menuItem.value}>
        {menuItem.label}
      </MuiMenuItem>
    ));

  const renderMenuItemGroups = (menuItemGroupsArg) => {
    const itemGroups = [];
    Object.entries(menuItemGroupsArg).map(([key, val]) => {
      const menuItems = renderMenuItems(val.menuItems);
      const renderListSubheader = <MuiListSubheader key={key}>{key}</MuiListSubheader>;
      return val.headerFeatureFlag
        ? (menuItemGroupsArg[val.headerFeatureFlag] && itemGroups.push([renderListSubheader, menuItems])) ?? null
        : itemGroups.push([renderListSubheader, menuItems]);
    });
    return itemGroups;
  };

  return (
    <MuiFormControl variant="outlined" className={classes.formControl} error={error}>
      <MuiInputLabel id={labelId}>{props.label}</MuiInputLabel>
      <MuiSelect {...props} id={componentIdStringGenerator('Select', props.id ? props.id : props.label ?? '')} labelId={labelId}>
        {menuItems && renderMenuItems(menuItems)}
        {menuItemGroups && renderMenuItemGroups(menuItemGroups)}
      </MuiSelect>
      {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
};

Select.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  menuItems: PropTypes.array,
  menuItemGroups: PropTypes.object,
};

export default Select;
