import PropTypes from 'prop-types';
import React from 'react';

import { Box, Link, Typography } from 'components/AORedesign';
import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';

const CommercialLinesLinks = ({ setErrorVisible, setErrorMessage }) => {
  const clsMainStreetStationQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'Main Street Station',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const clsMSCPolicyAdministrationQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'MSC Policy Administration',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const clsMSEAgencySolutionCenterQuickLink = () => {
    const request_body = {
      Area: 'Commercial Lines',
      TypeOfRedirect: 'MSE Agency Solution Center',
      DetailedContext: [
        {
          name: 'system',
          value: 'SBSS',
        },
      ],
    };
    return redirect(request_body);
  };

  const clsBondsQuickLink = () => {
    const request_body = {
      Area: 'Policy Services Menu',
      TypeOfRedirect: 'Surety and Fidelity Bonds',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const handleBondsTrainingClick = () => {
    const request_body = {
      Area: 'Training',
      TypeOfRedirect: 'Surety and Fidelity Bonds',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const redirect = (request_body) => {
    return redirectUrlFunction(request_body, 'POST', null, setErrorMessage, setErrorVisible);
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h6">Commercial Lines</Typography>
      </Box>
      <Box mt={1}>
        <Link onClick={clsMainStreetStationQuickLink} endIcon="openInNew">
          Main Street Station (CLMSS)
        </Link>
      </Box>
      <Box mt={1}>
        <Link onClick={clsMSCPolicyAdministrationQuickLink} endIcon="openInNew">
          MSC+ Policy Administration
        </Link>
      </Box>
      <Box mt={1}>
        <Link onClick={clsMSEAgencySolutionCenterQuickLink} endIcon="openInNew">
          MSE/MSC+ Agency Solution Center
        </Link>
      </Box>
      <Box mt={1}>
        <Link onClick={clsBondsQuickLink} endIcon="openInNew">
          Bonds
        </Link>
      </Box>
      <Feature allowed={featureFlags.PRODUCTION_ONLY}>
        <Feature allowed={featureFlags.BONDS_TRAINING_PERMITTED}>
          <Box mt={1}>
            <Link onClick={handleBondsTrainingClick} endIcon="openInNew">
              Bonds Training
            </Link>
          </Box>
        </Feature>
      </Feature>
    </>
  );
};

CommercialLinesLinks.defaultProps = {};

CommercialLinesLinks.propTypes = {
  setErrorVisible: PropTypes.func,
  setErrorMessage: PropTypes.func,
};

export default CommercialLinesLinks;
