import axios from 'axios';
import moment from 'moment';

import { GET_ALLOWED_AGENCY_CODES, GET_APPETITE } from '../../../constants/api';
import { ROUTE_CLIX, ROUTE_CL_PRODUCT_DETAIL } from '../../../constants/routes';
import { redirectUrlFunction } from '../../CommonUtilityServices/RedirectService';

const screenMappings = {
  start: 'FindProductToQuote',
  products: 'FindProductToQuoteProducts',
};

const REQUIRED_FIELD_ERROR_TEXT = {
  businessTypeKeywords: 'Provide Business Type Keywords.',
  state: 'Select a State.',
  zipCode: 'Provide a ZIP Code.',
  businessLocationType: 'Select a Business Location Type.',
  employees: 'Select Employees.',
  vehicles: 'Select Vehicles.',
  locationCode: 'Enter or Select Location Code Using the Agent Lookup.',
};

const landlordOnlyMenuItems = ['Lessors Risk Building', 'Lessors Risk Condo'].map((type) => ({
  label: type,
  value: type,
}));

const tenantOnlyMenuItems = ['Client Leases Building', 'Client Leases Condo', 'Client Leases Kiosk'].map((type) => ({
  label: type,
  value: type,
}));

const ownerOccupiedMenuItems = ['Client’s Home', 'Client Owns Building', 'Client Owns Condo'].map((type) => ({
  label: type,
  value: type,
}));

const businessLocationMenuItemGroups = {
  'LANDLORD ONLY': {
    menuItems: landlordOnlyMenuItems,
  },
  'TENANT ONLY': { menuItems: tenantOnlyMenuItems },
  'OWNER OCCUPIED': { menuItems: ownerOccupiedMenuItems },
};

const employeeMenuItems = [
  { label: 'No', value: 'No' },
  { label: 'Yes, part-time', value: 'Yes, part' },
  { label: 'Yes, full-time', value: 'Yes, full' },
  { label: 'Yes, both part-time and full-time', value: 'Yes, both' },
];

const yesNoMenuItems = ['Yes', 'No'].map((type) => ({ label: type, value: type }));

const fetchFormSuggestions = async (searchQuery) => {
  const suggestedNaicsUrl = `${GET_APPETITE}/suggestedNaics/${searchQuery}`;
  const suggestedNaicsData = await axios.get(`${suggestedNaicsUrl}`);
  const suggestedNaicsSuggestion = suggestedNaicsData.data;
  return suggestedNaicsSuggestion;
};

const getLOB = (formData) => {
  let lOB = 'BOP+CGL+BMISC+UMBRC';

  if (formData.employees === 'Yes' || formData.employees.includes('Yes')) {
    lOB += '+WORK';
  }
  if (formData.vehicles === 'Yes') {
    lOB += '+AUTOB';
  }

  return lOB;
};

const getMSELOB = (statusTags, formData) => {
  let result = getLOB(formData);
  for (const lob in statusTags) {
    if (lob.includes('LOB-')) {
      result = lob.split('LOB-')[1];
    }
  }
  return result;
};

const fetchSuggestedProducts = async (
  formData,
  setServiceUnavailable,
  setSuggestedProducts,
  setCurrentScreen,
  formInputMetadata,
  setAppetiteData,
  setButtonLoading,
) => {
  setButtonLoading((prevState) => ({ ...prevState, next: true }));
  try {
    let lOBParam = 'lineOfBusiness=';
    lOBParam += getLOB(formData);

    let getAppetiteProductsUrl = `${GET_APPETITE}/products?${lOBParam}`;
    getAppetiteProductsUrl += `&naics=${formData.businessTypeKeywords.code}`;
    getAppetiteProductsUrl += `&geos=${formData.state}`;
    getAppetiteProductsUrl += `&locationCode=${formData.locationCode}`;

    const suggestedProducts = await axios.get(getAppetiteProductsUrl);
    setSuggestedProducts(suggestedProducts.data);
    setAppetiteData({ formData, formInputMetadata, suggestedProducts: suggestedProducts.data });
    setCurrentScreen(screenMappings.products);
  } catch (error) {
    console.error(error);
    setServiceUnavailable(true);
  }
  setButtonLoading((prevState) => ({ ...prevState, next: false }));
};

const getOptionLabel = (option) => option.value ?? option;

const handleAOAutocompleteOnChange = (property, e, value, formData, setFormData) => {
  setFormData({ ...formData, [property]: value });
};

const handleAOAutocompleteOnInputChange = (
  property,
  e,
  value,
  autocompleteInputValues,
  setAutocompleteInputValues,
  debouncedState,
  setDebouncedState,
  setFormInputMetadata,
) => {
  setAutocompleteInputValues({
    ...autocompleteInputValues,
    [property]: {
      ...autocompleteInputValues[property],
      value,
    },
  });
  setDebouncedState({ ...debouncedState, [property]: { ...debouncedState[property], value } });
  setFormInputMetadata((prevState) => ({
    ...prevState,
    [property]: { ...prevState[property], invalidText: '' },
  }));
};

const handleOnChange = (e, formData, setFormData, setFormInputMetadata) => {
  const target = e.target;
  const value = target.value;
  const name = target.name;
  setFormData({ ...formData, [name]: value });
  setFormInputMetadata((prevState) => ({
    ...prevState,
    [name]: { ...prevState[name], invalidText: '' },
  }));
};

const handleNextOnClick = async (
  formData,
  formInputMetadata,
  setFormInputMetadata,
  setSuggestedProducts,
  setCurrentScreen,
  setServiceUnavailable,
  setAppetiteData,
  setButtonLoading,
) => {
  const ignoredValidationFields = ['locationCode'];

  let formContainsInvalidFields = false;
  const newFormInputMetadata = { ...formInputMetadata };
  for (const field in formData) {
    if ((formData[field] === null || formData[field] === '') && ignoredValidationFields.indexOf(field) === -1) {
      newFormInputMetadata[field].invalidText = REQUIRED_FIELD_ERROR_TEXT[field];
      formContainsInvalidFields = true;
    } else {
      newFormInputMetadata[field].invalidText = '';
    }
  }

  setFormInputMetadata(newFormInputMetadata);

  if (!formContainsInvalidFields) {
    await fetchSuggestedProducts(formData, setServiceUnavailable, setSuggestedProducts, setCurrentScreen, formInputMetadata, setAppetiteData, setButtonLoading);
  }
};

const fetchMidvaleCodes = async (riskState) => {
  let results = [];
  try {
    const selectedEffectiveDateFormat = moment().format('YYYY-MM-DD');

    const allowedAgencies = await axios.get(
      `${GET_ALLOWED_AGENCY_CODES}/${riskState}/${selectedEffectiveDateFormat}/Main Street Express`, // The MSE part does not matter as lob defined in API.
    );

    results = allowedAgencies?.data?.Results;
  } catch (error) {
    console.error('API call to get CL agent codes failed, skipping validation.');
    console.error(error);
  }

  return results;
};

const handleSetSelectedProduct = async (product, setSelectedProduct, setSelectedAppetiteProduct, setFormInputMetadata) => {
  const productObj = JSON.parse(product);
  setSelectedProduct(product);
  setSelectedAppetiteProduct(productObj.id);
  setFormInputMetadata((prevState) => ({ ...prevState, selectedProduct: { invalidText: '' } }));
};

const handleBackOnClickFromProducts = (
  setCurrentScreen,
  formInputMetadata,
  setFormInputMetadata,
  setSelectedProduct,
  setSelectedAppetiteProduct,
  appetiteData,
  setAppetiteData,
  setError,
  setIsErrorVisible,
  setFormData,
) => {
  setError();
  setIsErrorVisible();
  setCurrentScreen(screenMappings.start);
  setFormInputMetadata({
    ...formInputMetadata,
    selectedProduct: { invalidText: '' },
  });
  setFormData((prevState) => ({ ...prevState, locationCode: '' }));
  setSelectedProduct('');
  setSelectedAppetiteProduct('');
  const appetiteDataCopy = JSON.parse(JSON.stringify(appetiteData));
  delete appetiteDataCopy.suggestedProducts;
  appetiteDataCopy.formData.locationCode = '';
  setAppetiteData(appetiteDataCopy);
};

const startQuote = async (history, product, formData, setError, setIsErrorVisible, setShowLoader, productMappingCode) => {
  if (isClix(product)) {
    history.push(ROUTE_CLIX);
  } else {
    const detailsToPass = JSON.stringify({
      Mappings: product.Mappings,
      coverageType: product.coverageType,
    });
    const statusTags = product.StatusTags;
    const typeOfRedirect = Object.keys(statusTags)
      .filter((key) => key.startsWith('PAS-'))
      .join(',');

    const requestPayload = {
      Area: 'Appetite Start Quote',
      TypeOfRedirect: typeOfRedirect,
      DetailedContext: [
        {
          name: 'naics',
          value: formData.businessTypeKeywords.code,
        },
        {
          name: 'geos',
          value: formData.state,
        },
        {
          name: 'lineOfBusiness',
          value: getMSELOB(statusTags, formData),
        },
        {
          name: 'locationCode',
          value: formData.locationCode,
        },
        {
          name: 'ProductDetails',
          value: detailsToPass,
        },
        {
          name: 'zipCode',
          value: formData.zipCode,
        },
        {
          name: 'businessLocationType',
          value: formData.businessLocationType,
        },
        {
          name: 'employees',
          value: formData.employees.includes('Yes') ? 'Yes' : 'No',
        },
        {
          name: 'vehicles',
          value: formData.vehicles,
        },
      ],
    };
    if (product?.StatusTags?.['PAS-MSE']) {
      if (product?.Mappings?.length === 1) {
        requestPayload.DetailedContext.push({ name: 'industry', value: product.Mappings[0].id });
      } else if (product?.Mappings?.length > 1 && productMappingCode?.length) {
        requestPayload.DetailedContext.push({ name: 'industry', value: productMappingCode });
      }
    }

    setError();
    setIsErrorVisible();
    setShowLoader(true);
    // Need to decide how to handle errors. What do we show the user or what flow to take.
    await redirectUrlFunction(requestPayload, 'POST', 'Start Quote', setError, setIsErrorVisible);
    setShowLoader(false);
  }
};

const isClix = (product) => {
  if (product?.StatusTags?.['PAS-CLIX'] && product?.Mappings?.length > 1) {
    return true;
  } else {
    return false;
  }
};

const handleStartQuoteOnClick = async (
  history,
  selectedProduct,
  formInputMetadata,
  setFormInputMetadata,
  setShowLoader,
  formData,
  setError,
  setIsErrorVisible,
  setShowNAICSCodeDialog,
  setShowAgentCodeValidation,
  locationCode,
) => {
  const errorText = !selectedProduct ? 'error' : '';

  setFormInputMetadata({
    ...formInputMetadata,
    selectedProduct: { invalidText: errorText },
  });

  if (errorText === '') {
    const product = JSON.parse(selectedProduct);

    if (product?.StatusTags?.['PAS-MSE'] && !locationCode) {
      return setShowAgentCodeValidation(true);
    }

    if (product?.StatusTags?.['PAS-MSE'] && product?.Mappings?.length > 1) {
      return setShowNAICSCodeDialog(product.Mappings);
    }

    await startQuote(history, product, formData, setError, setIsErrorVisible, setShowLoader);
  }
};

const handleLearnMoreOnClick = (e, productValue) => {
  const productObj = JSON.parse(productValue);
  const route = `${ROUTE_CL_PRODUCT_DETAIL}/${productObj.id}`;
  window.open(route, '_blank', 'noopener, noreferrer');
};

export {
  REQUIRED_FIELD_ERROR_TEXT,
  businessLocationMenuItemGroups,
  employeeMenuItems,
  fetchFormSuggestions,
  fetchMidvaleCodes,
  getOptionLabel,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleBackOnClickFromProducts,
  handleLearnMoreOnClick,
  handleNextOnClick,
  handleOnChange,
  handleSetSelectedProduct,
  handleStartQuoteOnClick,
  screenMappings,
  startQuote,
  yesNoMenuItems,
};
