import MuiArrowBack from '@material-ui/icons/ArrowBack';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AOAppetiteAutocomplete } from 'components/AOAutocomplete';
import { AOCircularProgress } from 'components/AOCircularProgress';
import { AOProductCard } from 'components/AOProductCard';
import { Alert, Box, Button, Paper, Select, TextField, Typography } from 'components/AORedesign';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { GET_APPETITE_HEALTH_CHECK, GET_APPETITE_STATES } from '../../../constants/api';
import AppDataContext from '../../../contexts/appData.context';
import { useDebounce } from '../../../hooks/useDebounce';
import useFetchSelectMenuItems from '../../../hooks/useFetchSelectMenuItems';
import { AppetiteServiceUnavailable } from '../../AppetiteServiceUnavailable';
import AppetiteAgentValidationModal from './AppetiteAgentValidationModal';
import {
  businessLocationMenuItemGroups as defaultBusinessLocationMenuItemGroups,
  employeeMenuItems,
  fetchFormSuggestions,
  getOptionLabel,
  handleAOAutocompleteOnChange,
  handleAOAutocompleteOnInputChange,
  handleBackOnClickFromProducts,
  handleLearnMoreOnClick,
  handleNextOnClick,
  handleOnChange,
  handleSetSelectedProduct,
  handleStartQuoteOnClick,
  screenMappings,
  startQuote,
  yesNoMenuItems,
} from './FindProductToQuote.api';
import FindProductToQuoteNAICSCodeDialog from './FindProductToQuoteNAICSCodeDialog';

const errorText = 'Something went wrong. Please try again or contact our Customer Service Department at (877) 927-5672.';

const FindProductToQuote = ({ handleCommercialCardOnClick, handleCommercialFindProductsOnClick }) => {
  /* 
    Storing the formData to context was used when the we redirected to the product details page.
    That functionality has changed and instead open in a new tab without the ability to Star Quote from there, purely informational.
    As a side effect, we now have a "smart" form that persists if the user closes out of the Start Quote workflow.
    In local dev mode, there is a slight delay because of performance of writing to context, but this is not noticeable in the deployed environments.
    If performance issues do arise from this view, this would the the first thing to investigate (refactor to redux or remove if smart form is not needed).
  */
  const { setSelectedAppetiteProduct, appetiteData, setAppetiteData } = useContext(AppDataContext);

  const [currentScreen, setCurrentScreen] = useState(screenMappings.start);
  const [businessTypeKeywordsOptions, setBusinessTypeKeywordsOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [serviceUnavailable, setServiceUnavailable] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [showNAICSCodeDialog, setShowNAICSCodeDialog] = useState([]);
  const [error, setError] = useState();
  const [isErrorVisible, setIsErrorVisible] = useState();
  const [selectedClassificationCode, setSelectedClassificationCode] = useState('');
  const [buttonLoading, setButtonLoading] = useState({ next: false });
  const [showAgentCodeValidation, setShowAgentCodeValidation] = useState(false);
  const [businessLocationMenuItemGroups, setBusinessLocationMenuItemGroups] = useState(defaultBusinessLocationMenuItemGroups);

  const [formInputMetadata, setFormInputMetadata] = useState({
    businessTypeKeywords: { value: '', invalidText: '' },
    state: { invalidText: '' },
    zipCode: { invalidText: '' },
    businessLocationType: { invalidText: '' },
    employees: { invalidText: '' },
    vehicles: { invalidText: '' },
    selectedProduct: { invalidText: '' },
    locationCode: { invalidText: '' },
  });
  const [formData, setFormData] = useState({
    businessTypeKeywords: null,
    state: '',
    zipCode: '',
    businessLocationType: '',
    employees: '',
    vehicles: '',
    locationCode: '',
  });

  const history = useHistory();
  const [debouncedState, setDebouncedState] = useDebounce(formInputMetadata);

  useEffect(() => {
    (async () => {
      // health check
      try {
        const response = await axios.get(GET_APPETITE_HEALTH_CHECK);
        console.info('Appetite Health Check Status:', response.data);
      } catch (error) {
        setServiceUnavailable(true);
        console.error('Appetite Health Check Error:', error);
      }

      // handles form retention
      if (appetiteData) {
        if (appetiteData.formData) {
          setFormData(appetiteData.formData);
        }
        if (appetiteData.formInputMetadata) {
          setFormInputMetadata(appetiteData.formInputMetadata);
        }
        if (appetiteData.suggestedProducts) {
          setSuggestedProducts(appetiteData.suggestedProducts);
          setCurrentScreen(screenMappings.products);
        }
      }
    })();
  }, []);

  useFetchSelectMenuItems(
    GET_APPETITE_STATES,
    (response) => {
      setStateOptions(
        response.data.StateList.map((state) => ({
          label: state.state_name,
          value: state.geos_code,
        })),
      );
    },
    () => {
      setError(errorText);
    },
  );

  useEffect(async () => {
    if (formInputMetadata.businessTypeKeywords.value.length) {
      try {
        const suggestions = await fetchFormSuggestions(formInputMetadata.businessTypeKeywords.value);
        const suggestionsMenuItems = suggestions.AvailableCodes.map((item) => ({
          value: `${item.description} (${item.code})`,
          code: item.hash,
          naicCode: item.code,
        }));

        setBusinessTypeKeywordsOptions(suggestionsMenuItems);
      } catch (err) {
        console.error(err);
        setError(errorText);
      }
    } else {
      setBusinessTypeKeywordsOptions([]);
    }
  }, [debouncedState.businessTypeKeywords]);

  const businessTypeKeywords = formData.businessTypeKeywords?.value;
  const businessTypeKeywordsNaicCode = formData?.businessTypeKeywords?.naicCode;
  const businessTypeKeywordsInputValue = formInputMetadata.businessTypeKeywords?.value;
  const businessTypeKeywordsInvalidText = formInputMetadata.businessTypeKeywords.invalidText;
  const state = formData.state;
  const businessLocationType = formData.businessLocationType;
  const stateInvalidText = formInputMetadata.state.invalidText;
  const zipCode = formData.zipCode;
  const zipCodeInvalidText = formInputMetadata.zipCode.invalidText;
  const businessLocationTypeInvalidText = formInputMetadata.businessLocationType.invalidText;
  const employeesInvalidText = formInputMetadata.employees.invalidText;
  const vehiclesInvalidText = formInputMetadata.vehicles.invalidText;
  const suggestedProductsAvailable = suggestedProducts?.AvailableProducts;
  const employees = formData.employees;
  const vehicles = formData.vehicles;
  const displayProductCardsInError = formInputMetadata.selectedProduct.invalidText.length;
  const productsAvailable = suggestedProducts?.AvailableProducts?.length > 0 && !serviceUnavailable;
  const noProductsAvailable = suggestedProducts?.AvailableProducts?.length === 0;
  const serviceAvailable = !serviceUnavailable;
  const naicsCodeText = formData?.businessTypeKeywords?.value;
  const nextButtonLoading = buttonLoading.next;

  const handleBusinessTypeKeywordsOnChange = (e, value) => {
    handleAOAutocompleteOnChange('businessTypeKeywords', e, value, formData, setFormData);
    // feature/E1PMAO-11042 - Anytime the Business Type Keywords change, then clear Business Location Type for user to reselect
    setFormData((prevState) => ({ ...prevState, businessLocationType: '' }));
    // filter options based on type, only show LANDLORD ONLY options if the nacis code starts with 5311
    const code = value?.naicCode;
    const isLROOptionEligible = code?.startsWith('5311');
    const updatedDefaultBusinessLocationMenuItemGroups = { ...defaultBusinessLocationMenuItemGroups };
    if (isLROOptionEligible === true) {
      delete updatedDefaultBusinessLocationMenuItemGroups['TENANT ONLY'];
      delete updatedDefaultBusinessLocationMenuItemGroups['OWNER OCCUPIED'];
    } else if (isLROOptionEligible === false) {
      delete updatedDefaultBusinessLocationMenuItemGroups['LANDLORD ONLY'];
    }
    setBusinessLocationMenuItemGroups(updatedDefaultBusinessLocationMenuItemGroups);
  };

  const handleBusinessTypeKeywordsOnInputChange = (e, value) =>
    handleAOAutocompleteOnInputChange(
      'businessTypeKeywords',
      e,
      value,
      formInputMetadata,
      setFormInputMetadata,
      debouncedState,
      setDebouncedState,
      setFormInputMetadata,
    );

  const handleInputOnChange = (e) => handleOnChange(e, formData, setFormData, setFormInputMetadata);

  const handleFindProductsOnClick = () => {
    handleNextOnClick(
      formData,
      formInputMetadata,
      setFormInputMetadata,
      setSuggestedProducts,
      setCurrentScreen,
      setServiceUnavailable,
      setAppetiteData,
      setButtonLoading,
    );

    // call method if passed/defined
    if (typeof handleCommercialFindProductsOnClick === 'function') {
      handleCommercialFindProductsOnClick();
    }
  };

  const handleSetProduct = (product) => handleSetSelectedProduct(product, setSelectedProduct, setSelectedAppetiteProduct, setFormInputMetadata);

  const handleBackFromProducts = () => {
    handleBackOnClickFromProducts(
      setCurrentScreen,
      formInputMetadata,
      setFormInputMetadata,
      setSelectedProduct,
      setSelectedAppetiteProduct,
      appetiteData,
      setAppetiteData,
      setError,
      setIsErrorVisible,
      setFormData,
    );

    // call method if passed/defined
    if (typeof handleCommercialCardOnClick === 'function') {
      handleCommercialCardOnClick();
    }
  };

  const handleStartQuote = () =>
    handleStartQuoteOnClick(
      history,
      selectedProduct,
      formInputMetadata,
      setFormInputMetadata,
      setShowLoader,
      formData,
      setError,
      setIsErrorVisible,
      setShowNAICSCodeDialog,
      setShowAgentCodeValidation,
      formData.locationCode,
    );

  const handleCancelOnClick = () => setShowNAICSCodeDialog([]);

  const handleMapCodeOnClick = () =>
    startQuote(history, JSON.parse(selectedProduct), formData, setError, setIsErrorVisible, setShowLoader, selectedClassificationCode);

  const handleCloseOnClick = () => {
    setShowAgentCodeValidation(false);
    setSelectedProduct('');
    setSelectedAppetiteProduct('');
  };

  const handleSetAgencyCode = (code) => {
    setFormData((prevState) => ({ ...prevState, locationCode: code }));
    const appetiteDataCopy = { ...appetiteData };
    appetiteDataCopy.formData.locationCode = code;
    setAppetiteData(appetiteDataCopy);
    setShowAgentCodeValidation(false);
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}

      {currentScreen === screenMappings.start && (
        <Feature allowed={featureFlags.APPETITE_ENABLED}>
          <Box id={'FindProductToQuote'}>
            {serviceUnavailable && <AppetiteServiceUnavailable />}

            {serviceAvailable && (
              <>
                <Box mb={4.5}>
                  <Typography id={'reportsHeaderText'} variant={'h5'}>
                    Tell us about the risk you are trying to quote.
                  </Typography>
                  <Box mb={1} />
                  <Typography id={'reportsSubtitleText'} variant={'subtitle2'} color={'textSecondary'}>
                    The more we know about the business, the better protection we can provide.
                  </Typography>
                </Box>

                <Box mb={4}>
                  <Typography id={'business-type-and-location-title'} variant={'h6'}>
                    Business Type and Location
                  </Typography>

                  <Box mt={2}>
                    <Box mb={2}>
                      <AOAppetiteAutocomplete
                        id={'product-to-quote-business-type-keywords'}
                        labelId={'product-to-quote-business-type-keywords-label'}
                        label={'Business Type Keywords'}
                        placeholder={'Enter Business Type Keywords'}
                        freeSolo={false}
                        disableClearable={false}
                        value={businessTypeKeywords}
                        inputValue={businessTypeKeywordsInputValue}
                        options={businessTypeKeywordsOptions}
                        invalidSearch={businessTypeKeywordsInvalidText}
                        handleOnChange={handleBusinessTypeKeywordsOnChange}
                        handleOnInputChange={handleBusinessTypeKeywordsOnInputChange}
                        getOptionLabel={getOptionLabel}
                      />
                    </Box>

                    <Select
                      id={'product-to-quote-state'}
                      labelId={'product-to-quote-state-label'}
                      label={'State'}
                      name={'state'}
                      value={state}
                      menuItems={stateOptions}
                      error={!!stateInvalidText}
                      helperText={stateInvalidText}
                      onChange={handleInputOnChange}
                    />
                    <Box display="inline-block" mr={2} />
                    <TextField
                      id={'product-to-quote-zip-code'}
                      labelId={'product-to-quote-zip-code-label'}
                      label={'ZIP Code'}
                      placeholder={'Enter ZIP Code'}
                      name={'zipCode'}
                      inputProps={{ maxLength: 5 }}
                      value={zipCode}
                      error={!!zipCodeInvalidText}
                      helperText={zipCodeInvalidText}
                      onChange={handleInputOnChange}
                    />
                  </Box>
                </Box>

                <Box mb={4}>
                  <Typography variant={'h6'}>Business Details</Typography>
                  <Box mt={2}>
                    <Box mb={2}>
                      <Select
                        id={'product-to-quote-business-type'}
                        labelId={'product-to-quote-business-type-label'}
                        label={'Business Location Type'}
                        name={'businessLocationType'}
                        value={businessLocationType}
                        menuItemGroups={businessLocationMenuItemGroups}
                        error={!!businessLocationTypeInvalidText}
                        helperText={businessLocationTypeInvalidText}
                        onChange={handleInputOnChange}
                      />
                    </Box>
                    <Box mb={2}>
                      <Select
                        id={'product-to-quote-employees-type'}
                        labelId={'product-to-quote-employees-label'}
                        label={'Employees'}
                        name={'employees'}
                        value={employees}
                        menuItems={employeeMenuItems}
                        error={!!employeesInvalidText}
                        helperText={employeesInvalidText}
                        onChange={handleInputOnChange}
                      />
                    </Box>
                    <Box>
                      <Select
                        id={'product-to-quote-vehicles-type'}
                        labelId={'product-to-quote-vehicles-label'}
                        label={'Vehicles'}
                        name={'vehicles'}
                        value={vehicles}
                        menuItems={yesNoMenuItems}
                        error={!!vehiclesInvalidText}
                        helperText={vehiclesInvalidText}
                        onChange={handleInputOnChange}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}

            {serviceAvailable && (
              <Button
                id={'cl-appetite-start-next-button'}
                color={'primary'}
                variant={'contained'}
                loading={nextButtonLoading}
                onClick={handleFindProductsOnClick}>
                Find Products
              </Button>
            )}
          </Box>
        </Feature>
      )}

      {currentScreen === screenMappings.products && (
        <>
          <Box id={'FindProductToQuoteProducts'}>
            <AOCircularProgress visible={showLoader}>Starting your quote...</AOCircularProgress>

            <Box mb={3.5}>
              <Typography id={'reportsHeaderText'} variant={'h5'}>
                Here are the best matched products.
              </Typography>
              <Box mb={1} />
              <Typography id={'reportsSubtitleText'} variant={'subtitle2'} color={'textSecondary'}>
                Based on the information provided, these are the products we would recommend.
              </Typography>
            </Box>

            {isErrorVisible && <Alert severity={'error'}>{error}</Alert>}

            <Box mb={5.75}>
              {productsAvailable && (
                <AOProductCard
                  idKey={'id'}
                  logoUrlKey={'logoUrl'}
                  recommendedKey={'STATUS-RECOMMENDED'}
                  nameKey={'name'}
                  descriptionKey={'description'}
                  products={suggestedProductsAvailable}
                  error={!!displayProductCardsInError}
                  selectedProduct={selectedProduct}
                  onChangeCallback={handleSetProduct}
                  onLearnMoreClick={handleLearnMoreOnClick}
                />
              )}
              {noProductsAvailable && (
                <Paper variant={'outlined'} square={true}>
                  <Typography>
                    <Box p={3}>There are currently no product recommendations that meet your risk appetite.</Box>
                  </Typography>
                </Paper>
              )}
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Button variant="text" color={'secondary'} startIcon={<MuiArrowBack />} onClick={handleBackFromProducts}>
                Back
              </Button>
              {productsAvailable && (
                <Button variant={'contained'} color={'primary'} onClick={handleStartQuote}>
                  Start Quote
                </Button>
              )}
            </Box>

            <FindProductToQuoteNAICSCodeDialog
              codeOptions={showNAICSCodeDialog}
              selectedProduct={selectedProduct}
              businessLocationType={businessLocationType}
              businessTypeKeywordsNaicCode={businessTypeKeywordsNaicCode}
              naicsCodeText={naicsCodeText}
              selectedClassificationCode={selectedClassificationCode}
              handleCancelOnClick={handleCancelOnClick}
              handleMapCodeOnClick={handleMapCodeOnClick}
              setSelectedClassificationCode={setSelectedClassificationCode}></FindProductToQuoteNAICSCodeDialog>
          </Box>
        </>
      )}

      {!formData.locationCode && showAgentCodeValidation && (
        <AppetiteAgentValidationModal
          state={formData?.state?.split('-')[1]}
          open={showAgentCodeValidation}
          handleCloseOnClick={handleCloseOnClick}
          handleSetAgencyCode={handleSetAgencyCode}
        />
      )}
    </>
  );
};

FindProductToQuote.propTypes = {
  handleCommercialCardOnClick: PropTypes.func,
  handleCommercialFindProductsOnClick: PropTypes.func,
};

export default FindProductToQuote;
