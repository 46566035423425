import React from 'react';

import { Box } from 'components/AORedesign';
import { Headline } from 'components/Headline';

const AppetiteServiceUnavailable = () => {
  return (
    <Box m={2}>
      <Headline
        id={'appetite-service-unavailable'}
        title={'Service Unavailable'}
        subtitle={'The service is currently not available. Please try again later.'}
      />
    </Box>
  );
};

export default AppetiteServiceUnavailable;
