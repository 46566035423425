import React, { useState } from 'react';

import { Alert, Box, Link, Typography } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import LinksContainer from './LinksContainer';

const PDF_ICON = 'pdf';

const AgencyFormRequests = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadFile = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    if (pdfUrl) {
      handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
    } else {
      const _fileUrl = event.currentTarget.getAttribute('data-file');
      handleFileDownload(_fileUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
    }
  };

  return (
    <Box mb={2}>
      <Box mt={1} mb={4}>
        <Typography variant="h6">Forms & Requests for Your Agency</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && (
        <Box mb={2}>
          <Alert color="error" alertTitleMessage={downloadingPdfError} />
        </Box>
      )}
      <Box>
        <LinksContainer heading="FORMS">
          <Link data-pdf={'/resources/Agency_EFT_Commission_Deposit_Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Electronic Commission & Agency Sweep Authorization Form
          </Link>
        </LinksContainer>
        <LinksContainer heading="commercial lines audit forms">
          <Link data-pdf={'/resources/Sales_Based_Self-Audit_Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Sales Based Self-Audit Form
          </Link>
          <Link data-pdf={'/resources/Payroll_Based_Self-Audit_Form-newest.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Payroll Based Self-Audit Form
          </Link>
          <Link data-pdf={'/resources/Audit_Dispute_Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Audit Dispute Form
          </Link>
          <Link data-pdf={'/resources/Audit_Information_Release_Authorization.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Audit Information Release Authorization Form
          </Link>
          <Link data-pdf={'/resources/Sedgwick_Basic_Information.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Sedgwick Audit Basic Information
          </Link>
          <Link data-pdf={'/resources/Sedgwick_Preparing_for_an_Audit.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Sedgwick Audit Preparing for an Audit
          </Link>
          <Link data-pdf={'/resources/Sedgwick_FAQs.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Sedgwick Audit FAQS
          </Link>
        </LinksContainer>
        <LinksContainer heading="requests">
          <Link href={'https://brandfolder.com/s/38xk5873nvp8ntcwhw256hxv'} endIcon="openInNew">
            Change of Agency Information Request
          </Link>
        </LinksContainer>
      </Box>
    </Box>
  );
};

export default AgencyFormRequests;
