import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import AppetiteAgentValidationModalExternal from './AppetiteAgentValidationModalExternal';
import AppetiteAgentValidationModalInternal from './AppetiteAgentValidationModalInternal';

const AppetiteAgentValidationModal = ({ open, handleCloseOnClick, state, handleSetAgencyCode }) => {
  const [agentCodes, setAgentCodes] = useState([]);
  const [selectedCode, setSelectedCode] = useState('');

  const modalProps = {
    agentCodes,
    selectedCode,
    open,
    setSelectedCode,
    setAgentCodes,
    handleSetAgencyCode,
    handleCloseOnClick,
    state,
  };

  return (
    <>
      <Feature allowed={featureFlags.ACCESS_USER_IS_AGENT}>
        <AppetiteAgentValidationModalExternal {...modalProps} />
      </Feature>
      <Feature allowed={featureFlags.ACCESS_USER_INTERNAL}>
        <AppetiteAgentValidationModalInternal {...modalProps} />
      </Feature>
    </>
  );
};

AppetiteAgentValidationModal.propTypes = {
  state: PropTypes.string,
  open: PropTypes.bool,
  handleCloseOnClick: PropTypes.func.isRequired,
  handleSetAgencyCode: PropTypes.func.isRequired,
};

export default AppetiteAgentValidationModal;
