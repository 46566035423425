import Grid from '@material-ui/core/Grid';
import { Alert, Box, Card, Link, Typography, ViewContainer } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { ROUTE_ACCOUNT_ADMIN } from 'constants/routes';
import React, { useState } from 'react';

const RegistrationMaterials = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadPdf = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');

    handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
  };

  const handleClickDownloadFile = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const _fileUrl = event.currentTarget.getAttribute('data-file');
    console.log(`_fileUrl is ${_fileUrl}`);

    handleFileDownload(_fileUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  return (
    <ViewContainer returnRoute={{ route: ROUTE_ACCOUNT_ADMIN, text: 'Download' }}>
      <Card>
        <Box mb={2}>
          <Box mt={1} mb={4}>
            <Typography variant="h6">Registration Materials</Typography>
          </Box>
          {isDownloadingPdfErrorVisible && <Alert color="error" alertTitleMessage={downloadingPdfError} />}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box mb={2}>
                <Typography variant="button" display="block" gutterBottom>
                  DOWNLOAD INSTRUCTIONS
                </Typography>
              </Box>
              <Box mt={1}>
                <Link href="https://brandfolder.com/s/35qb7t4xbq975bkpqpht235c" endIcon="openInNew">
                  Download Setup Form
                </Link>
              </Box>
              <Box mt={1}>
                <Link data-file="/resources/Transactions%20supported%20in%20MSA%20Download.doc" onClick={handleClickDownloadFile} endIcon="file">
                  Download Transactions Supported
                </Link>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mb={2}>
                <Typography variant="button" display="block" gutterBottom>
                  CERTIFIED BUSINESS / CODES
                </Typography>
              </Box>
              <Box mt={1}>
                <Link data-file="/resources/Certified%20Lines%20of%20Business.doc" onClick={handleClickDownloadFile} endIcon="file">
                  Certified Lines of Business
                </Link>
              </Box>
              <Box mt={1}>
                <Link data-file="/resources/Certified%20vendors.doc" onClick={handleClickDownloadFile} endIcon="file">
                  Certified Vendors
                </Link>
              </Box>
              <Box mt={1}>
                <Link href="https://brandfolder.com/s/kfbwp9f48bnsprnrtznmvhxs" endIcon="openInNew">
                  Company Unique Coverage Codes
                </Link>
              </Box>
              <Box mt={1}>
                <Link data-file="/resources/Main%20Street%20America%20Writing%20Companies.doc" onClick={handleClickDownloadFile} endIcon="file">
                  Main Street America Writing Companies
                </Link>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mb={2}>
                <Typography variant="button" display="block" gutterBottom>
                  FORMS / QUICK REFERENCE
                </Typography>
              </Box>
              <Box mt={1}>
                <Link
                  onClick={resetErrorVisibility}
                  href="mailto:ITSupportServices@msagroup.com?subject=Download/Retransmit&body=Please provide the required information below to request a download or retransmission of a policy.%0A%0ANamed Insured:%0APolicy number:%0AAgency code:%0ATransaction Type:%0AEffective Date of Download:%0AReason for Download Retransmit:%0A%0ABe advised that items transmitted after Jan. 8, 2018 can be obtained via self-service at your agency's IVANS Exchange.%0A%0APlease note that only the last transaction processed on a policy can be retransmitted.%0A%0ADid you know the IVANS Exchange website offers agents the ability to log-in and self-serve all of your download needs? IVANS can assist you with logging into IVANS Exchange and provide instructions on how to retrieve Main Street America downloads since our conversion on Jan. 8, 2018.  The IVANS Exchange Help Desk can be reached at support@ivansinsurance.com"
                  endIcon="mail">
                  Download/Retransmit
                </Link>
              </Box>
              <Box mt={1}>
                <Link data-pdf="/resources/Indemnity%20form.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
                  Indemnity Form
                </Link>
              </Box>
              <Box mt={1}>
                <Link href="https://brandfolder.com/s/nnmpgsxs9vx5x8sk49x9t8cn" endIcon="openInNew">
                  Direct Bill Commissions Download Form
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </ViewContainer>
  );
};

export default RegistrationMaterials;
