import { Select } from 'components/AORedesign/Select';
import PropTypes from 'prop-types';
import { default as React, useContext } from 'react';

import { default as getUSStateData } from 'components/AORedesign/USAMap/data/usa-map-dimensions';
import agentContext from 'contexts/agent.context';

const SelectStates = ({ label, name, value, onChange, includeAllOption = false, onlyUsersAssignedStates = false, ...props }) => {
  // get the users assigned state codes
  const { StateCodes } = useContext(agentContext);

  // build array of value/label for all states
  const menuItems = [];

  // include the ALL option is asked
  if (includeAllOption) menuItems.push({ value: 'ALL', label: 'All States' });

  Object.values(getUSStateData()).forEach((item) => {
    // add state to drop down is we are showing all states OR the state code is in the users assigne states
    if (!onlyUsersAssignedStates || StateCodes.includes(item.abbreviation)) menuItems.push({ value: item.abbreviation, label: item.name });
  });

  return <Select label={label} name={name} value={value} menuItems={menuItems} onChange={onChange} {...props} />;
};

SelectStates.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  includeAllOption: PropTypes.bool,
  onlyUsersAssignedStates: PropTypes.bool,
};

export default SelectStates;
