import { Box, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { GET_ALL_AGENTS } from '../../../constants/api';
import { AgentLookupModal } from '../../AgentLookupModal';
import { AOCircularProgress } from '../../AOCircularProgress';
import { AOIconButton } from '../../AOIconButton';
import { Button, TextField } from '../../AORedesign';

const AppetiteAgentValidationModalInternal = ({ selectedCode, setSelectedCode, handleSetAgencyCode, handleCloseOnClick, open, agentCodes, setAgentCodes }) => {
  const [isAgentLookupModalOpen, setAgentLookupModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  // Fetches agent codes to store and validate if the input value is a valid code
  useEffect(() => {
    (async () => {
      try {
        const results = await axios.get(`${GET_ALL_AGENTS}/commercial-lines`);
        setAgentCodes(results.data.Results.map((agency) => agency.agencyCode ?? ''));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleValidateInternalUser = (selectedCode) => {
    if (agentCodes.includes(selectedCode)) {
      handleSetAgencyCode(selectedCode);
    } else {
      setErrorMessage('Not a valid agency code.');
    }
  };

  return loading ? (
    <AOCircularProgress visible={loading}>Fetching agent codes...</AOCircularProgress>
  ) : (
    <>
      <Modal
        open={open}
        onClose={handleCloseOnClick}
        aria-labelledby="appetite-agent-code-validation-modal-title"
        aria-describedby="appetite-agent-code-validation-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper elevation={3}>
          <Box p={3} maxWidth={500}>
            <Typography variant="h6">Enter an agency code</Typography>
            <Box mb={2} />
            <Typography color="textSecondary">Select the correct agency code to continue with this quote.</Typography>
            <Box mb={2} />
            <Typography color="textSecondary">{`To continue with this quote, click the "Start Quote" button.`}</Typography>
            <Box mb={2} />
            <Box display="flex">
              <TextField
                id="appetite-agent-code"
                label={'Agency Code'}
                name="locationCode"
                onChange={(e) => {
                  setErrorMessage('');
                  setSelectedCode(e.target.value);
                }}
                error={!!errorMessage}
                helperText={errorMessage}
                value={selectedCode}
              />
              <Box ml={0.25} mt={0.25}>
                <AOIconButton id="appetite-agent-lookup-button" onClick={() => setAgentLookupModalOpen(true)} />
              </Box>
            </Box>
            <AgentLookupModal
              open={isAgentLookupModalOpen}
              setAgentCode={(value) => {
                setErrorMessage('');
                setSelectedCode(value);
              }}
              lineOfBusiness="commercial-lines"
              setOpen={setAgentLookupModalOpen}
            />
            <Box mt={2} display="flex" justifyContent={'flex-end'}>
              <Button color="secondary" variant="text" onClick={handleCloseOnClick}>
                Close
              </Button>
              <Box mr={1} />

              <Button
                id="appetite-use-this-code"
                variant="text"
                color="secondary"
                onClick={() => handleValidateInternalUser(selectedCode)}
                disabled={!selectedCode}>
                Use This Code
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

AppetiteAgentValidationModalInternal.propTypes = {
  open: PropTypes.bool,
  selectedCode: PropTypes.string,
  setSelectedCode: PropTypes.func,
  handleSetAgencyCode: PropTypes.func,
  handleCloseOnClick: PropTypes.func,
  agentCodes: PropTypes.array,
  setAgentCodes: PropTypes.func,
};

export default AppetiteAgentValidationModalInternal;
